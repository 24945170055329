<template>
  <div v-if="respData && $ability.can('read', this.$route.meta.resource)">
    <b-card :title="$t('System')">
      <b-row>
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 20%;">{{ $t('System list') }}</b-td>
                <b-td>{{ $t('Setting') }}</b-td>
                <b-td style="width: 20%;">{{ $t('System list') }}</b-td>
                <b-td>{{ $t('Setting') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ $t('Wallet') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null ? respData['system']['wallet'] : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.wallet', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["wallet"] == true
                          ? "เปิดระบบกระเป๋าเงิน"
                          : "ปิดระบบกระเป๋าเงิน"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Point') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['point']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.point', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["point"] == true
                          ? "เปิดระบบคะแนน"
                          : "ปิดระบบคะแนน"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('E-Commerce') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null ? respData['system']['ecommerce'] : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.ecommerce', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["ecommerce"] == true
                          ? "เปิดระบบซื้อขายออนไลน์"
                          : "ปิดระบบซื้อขายออนไลน์"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>

                </b-td>
                <b-td>{{ $t('Chat') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['chat']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.chat', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["chat"] == true
                          ? "เปิดระบบแชท"
                          : "ปิดระบบแชท"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>

                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Cart') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null ? respData['system']['cart'] : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.cart', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["cart"] == true
                          ? "เปิดระบบตะกร้า"
                          : "ปิดระบบตะกร้า"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Feed') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['feed']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.feed', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["feed"] == true
                          ? "เปิดระบบฟีด"
                          : "ปิดระบบฟีด"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Stock') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null ? respData['system']['stock'] : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.stock', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["stock"] == true
                          ? "เปิดระบบคลังสินค้า"
                          : "ปิดระบบคลังสินค้า"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Claim') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['claim']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.claim', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["claim"] == true
                          ? "เปิดระบบเคลม"
                          : "ปิดระบบเคลม"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Member') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['member']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.member', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["member"] == true
                          ? "เปิดระบบสมัครสมาชิก"
                          : "ปิดระบบสมัครสมาชิก"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Review') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['review']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.review', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["review"] == true
                          ? "เปิดระบบรีวิว"
                          : "ปิดระบบรีวิว"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Account Number') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['accountNumber']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.accountNumber', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["accountNumber"] == true
                          ? "เปิดระบบเลขบัญชี"
                          : "ปิดระบบเลขบัญชี"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Coupon') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['coupon']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.coupon', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["coupon"] == true
                          ? "เปิดระบบคูปอง"
                          : "ปิดระบบคูปอง"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Flash Sale') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['flashSale']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.flashSale', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["flashSale"] == true
                          ? "เปิดระบบลดราคาตามเวลา"
                          : "ปิดระบบลดราคาตามเวลา"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Recommend Product') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['recommendProduct']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.recommendProduct', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["recommendProduct"] == true
                          ? "เปิดระบบสินค้าแนะนำ"
                          : "ปิดระบบสินค้าแนะนำ"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Best Seller') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['bestSeller']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.bestSeller', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["bestSeller"] == true
                          ? "เปิดระบบแสดงสินค้าขายดี"
                          : "ปิดระบบแสดงสินค้าขายดี"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Promotion') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['promotion']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.promotion', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["promotion"] == true
                          ? "เปิดระบบโปรโมชัน"
                          : "ปิดระบบโปรโมชัน"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Voice chat') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['voiceChat']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.voiceChat', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["voiceChat"] == true
                          ? "เปิดระบบแชทด้วยเสียง"
                          : "ปิดระบบแชทด้วยเสียง"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Wholesale Box') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['wholesaleBox']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.wholesaleBox', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["wholesaleBox"] == true
                          ? "เปิดระบบกล่องขายส่ง"
                          : "ปิดระบบกล่องขายส่ง"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Credit') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['credit']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.credit', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["credit"] == true
                          ? "เปิดระบบเครดิต"
                          : "ปิดระบบเครดิต"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
                <b-td>{{ $t('Invite') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox :checked="respData['system'] != null
                      ? respData['system']['invite']
                      : false
                      " class="custom-control-primary ml-2" name="check-button" switch
                      @change="switchChange('system.invite', $event)" />
                    <b-card-text>
                      {{
                        respData["system"] != null
                        ? respData["system"]["invite"] == true
                          ? "เปิดระบบเชิญเพื่อน"
                          : "ปิดระบบเชิญเพื่อน"
                        : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>


      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTableSimple, BThead, BTh, BTbody, BTr, BTd } from 'bootstrap-vue'

import {
  // BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  // BFormGroup,
  // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/eCommerce/system'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'setupSystem'

export default {
  components: {
    BTableSimple, BThead, BTh, BTbody, BTr, BTd,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    // BFormGroup,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Color
      // currentVersion: '0.0.1',
      // otpSetting
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    currentVersion: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.currentVersion
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'currentVersion',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {

      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    inputChange(name) {
      const obj = {
        currentVersion: store.state[STORE_MODULE_NAME].respData.currentVersion,
      }
      if (name === 'currentVersion') {
        obj.currentVersion = this.currentVersion
      }
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {

      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
